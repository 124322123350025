import api from '@/services/api';

export async function getProjetos(type, page) {
    const response = await api.get(`/${type}/projs?page=${page}`);
    if (response.status !== 200) throw new Error('Erro ao exibir projetos');
    return response.data.objects;
}

export async function newProjeto(data) {
    var formData = new FormData();
    formData.append("id_client", data.id_client);
    formData.append("title", data.title);
    formData.append("url", data.url);
    formData.append("thumbnail", data.thumbnail[0]);

    const response = await api.post('/adm/projs', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    if (response.status !== 200) throw new Error('Erro ao cadastrar projeto');
    return response.data;
}

export async function getUserProjetos(id, page) {
    const response = await api.get(`/adm/projs/u/${id}?page=${page}`);
    if (response.status !== 200) throw new Error('Erro ao exibir projetos');
    return response.data.objects;
}

export async function viewProjeto(id) {
    const response = await api.get(`/adm/projs/v/${id}`);
    if (response.status !== 200) throw new Error('Erro ao exibir projetos');
    return response.data.objects;
}

export async function updateProjeto(data, id) {
    const response = await api.put(`/adm/projs/v/${id}`, data);
    if (response.status !== 200) throw new Error('Erro ao atualizar projetos');
    return response.data;
}

export async function deleteProjeto(id) {
    const response = await api.get(`/adm/projs/d/${id}`);
    if (response.status !== 200) throw new Error('Erro ao exibir projetos');
    return response.data;
}