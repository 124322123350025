// import React, { useState } from 'react';
import React from 'react';
import { NavLink } from "react-router-dom";

export default function MenuRouter() {
    return (
        <div className="space-y-6 block sm:inline-flex sm:p-0 sm:space-y-0 sm:space-x-8">
            <NavLink to="/" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Início</NavLink>
            <NavLink to="/" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Quem Somos</NavLink>
            <NavLink to="/" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">BIM</NavLink>
            <NavLink to="/" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Serviços</NavLink>
            <NavLink to="/" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Produtos</NavLink>
            <NavLink to="/cases" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Portfólio</NavLink>
            <NavLink to="/blog" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Blog</NavLink>
            <NavLink to="/" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Contato</NavLink>
            <NavLink to="/login" className="block font-medium uppercase opacity-75 text-primary hover:opacity-100">Login</NavLink>
        </div>
    );
}