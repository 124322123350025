import React from 'react';
import { Link } from "react-router-dom";

export const PostCard = (props) => {
    return (
        <Link to={`/blog/${props.data.slug}`}>
            <div className="relative max-w-screen-md h-96 mb-4 md:mb-0 w-full mx-auto">
                <div className="absolute left-0 bottom-0 w-full h-full bg-gradient-to-b from-transparent to-black z-10"/>
                <img src={props.data.thumbnail} className="absolute left-0 top-0 w-full h-full z-0 object-cover" alt="" />
                <div className="p-4 absolute bottom-0 left-0 z-20">
                    <span className="px-4 py-1 bg-primary text-black inline-flex items-center justify-center mb-2">
                        {props.data.category}
                    </span>
                    <h2 className="text-2xl font-semibold text-gray-100 leading-tight">
                        {props.data.title}
                    </h2>
                    <div className="flex mt-3">
                        <img src={props.data.author?.thumbnail} className="h-10 w-10 rounded-full mr-2 object-cover" alt="" />
                        <div>
                            <p className="font-semibold text-gray-200 text-sm"> {props.data.author?.name ?? 'Sem Autor'} </p>
                            <p className="font-semibold text-gray-400 text-xs"> {props.data.date} </p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}