import axios from 'axios';
import api from '@/services/api';

export async function getClients(page) {
    const response = await api.get(`/adm/clients?page=${page}`);
    if (response.status !== 200) throw new Error('Erro ao exibir clientes');
    return response.data.objects;
}

export async function viewClient(id) {
    const response = await api.get(`/adm/client/v/${id}`);
    return response.data.objects;
}

export async function viewMe() {
    const response = await api.get(`/client/me`);
    return response.data.objects;
}

export async function newClient(data) {
    const response = await api.post('/adm/client', data);
    if (response.status !== 200) throw new Error('Erro ao cadastrar cliente');
    return response.data;
}

export async function updateClient(data, id) {
    const response = await api.put(`/adm/client/v/${id}`, data);
    if (response.status !== 200) throw new Error('Erro ao cadastrar cliente');
    return response.data.success;
}

export async function updateMe(data) {
    const response = await api.put(`/client/me`, data);
    return response.data.success;
}

export async function removeClient(id) {
    const response = await api.get(`/adm/client/d/${id}`);
    return response.data;
}

export async function selfRegister(data) {
    try {
        const response = await api.post(`/register`, data);
        return response.data;
    } catch(error) {
        return;
    }
}


export async function SearchByCep(cep) {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return response.data;
}