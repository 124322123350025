import axios from 'axios';
import { getAuth } from '@/lib/auth';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL_PROD
});

api.interceptors.request.use(
    config => {
        const { token } = getAuth();
        if (token) config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    error => {
        Promise.reject(error);
    }
)

export default api;