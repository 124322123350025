import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";

import { getProjetos } from '@/lib/projetos';
import { FaPlusCircle } from 'react-icons/fa';

import { PrivateSidebar } from '@/components/PrivateSidebar';
import { Pagination } from '@/components/Pagination';
import { ProjAdmCard, ProjCardLoad } from '@/components/Cards/ProjCard';

export default function ProjAdmPage() {
    const { page } = useParams();
    const [projs, setProjs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function init() {
            setProjs(await getProjetos('adm', page ?? 1));
            setLoading(false);
        }
        init();
    }, [projs, page]);

    return (
        <div>
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
                <PrivateSidebar userType="ADMIN" />
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="flex flex-wrap items-center mb-8">
                            <div className="text-center sm:text-left">
                                <h2 className="text-3xl font-bold">
                                    Todos os <span className="text-primary">Projetos</span> ({projs.total})
                                </h2>
                            </div>
                            <div className="flex-grow flex text-right">
                                <button
                                    onClick={() => console.log('pasta')}
                                    className="flex items-center w-36 ml-auto bg-indigo-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1">
                                    <FaPlusCircle className="mr-2" /> 
                                    Nova Pasta
                                </button>
                                <NavLink
                                    to="/adm/proj/n"
                                    className="flex items-center w-36 bg-indigo-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1">
                                    <FaPlusCircle className="mr-2" /> 
                                    Novo Projeto
                                </NavLink>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        {
                            !loading ? (
                                projs.data.length ?
                                    projs.data.map((item) => {
                                        return (
                                            <ProjAdmCard data={ item } />
                                        )
                                    }) : <span>sem resultados</span>    
                            ) :
                            <>
                                <ProjCardLoad />
                                <ProjCardLoad />
                                <ProjCardLoad />
                                <ProjCardLoad />
                            </>
                        }
                        </div>
                        { !loading && <Pagination data={projs} route="/adm/proj" /> }
                    </div>
                </main>
            </div>
        </div>
    );
}