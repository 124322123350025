import React from 'react';

import { Header } from '@/components/header';
import Footer from '@/components/Footer';
import { PortCard } from '@/components/PortCase';

// api mockada.
import cases from '@/utils/cases';

export default function CasesPage() {
    return (
        <div className="relative bg-black">
            <Header />
            <main>
                <section className="relative min-h-screen bg-black">
                    <div className="max-w-5xl m-auto">
                        <div className="text-center pt-32 sm:mb-20">
                            <h2 className="text-5xl font-bold text-primary">
                                Cases e<br/>
                                <span className="text-white">Portfólio</span>
                            </h2>
                            <span className="block w-32 h-2 bg-primary mx-auto mt-4"/>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-10">
                            {cases.map((item) => {
                                return (
                                    <PortCard data={item} />
                                )
                            })}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}