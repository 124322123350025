import React from 'react';
import { Link } from "react-router-dom";

export const PortCard = (props) => {
    return (
        <Link to={`/cases/${props.data.slug}`}>
            <div className="relative max-w-screen-md h-96 mb-4 md:mb-0 w-full mx-auto bg-gray-700">
                <img src={props.data.thumbnail} className="absolute left-0 top-0 w-full h-full z-0 object-cover" alt="" />
                <div className="p-4 absolute bg-gray-800 inset-x-0 bottom-0 z-20">
                    <h2 className="text-2xl font-semibold text-primary leading-tight">
                        {props.data.title}
                    </h2>
                    <p className="pt-3 text-gray-100" dangerouslySetInnerHTML={ {__html: props.data.description} }></p>
                </div>
            </div>
        </Link>
    );
}