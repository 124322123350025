import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { PrivateSidebar } from '@/components/PrivateSidebar';
import { Pagination } from '@/components/Pagination';

import { FaTrashAlt, FaRegEye, FaArchive, FaPlusCircle, FaSpinner } from 'react-icons/fa';
import { getClients, removeClient } from '@/lib/clients';

export default function ClientAdmPage() {
    const [client, setClient] = useState([]);
    const [loading, setLoading] = useState(true);
    const [removing, setRemoving] = useState(null);

    async function remove(id) {
        setRemoving(id);
        await removeClient(id);
        setRemoving(null);
        setLoading(true);
        await init();
    }

    async function init() {
        setClient(await getClients());
        setLoading(false);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
                <PrivateSidebar userType="ADMIN" />
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="flex flex-wrap items-center mb-8">
                            <div className="text-center sm:text-left">
                                <h2 className="text-3xl font-bold">
                                    Todos os <span className="text-primary">Clientes</span>
                                </h2>
                            </div>
                            <div className="flex-grow flex-1 text-right">
                                <NavLink to="/adm/cli/n" className="flex items-center w-36 ml-auto bg-indigo-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1">
                                    <FaPlusCircle className="mr-2" /> Novo Cliente
                                </NavLink>
                            </div>
                        </div>
                        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                <thead>
                                    <tr className="text-left">
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> ID </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Nome </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Email </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Criado em </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    !loading ? (
                                        client.data.length ?
                                            client.data.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td className="border-dashed border-t border-gray-200">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                { item.id }
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                { item.name }
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                { item.email }
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                { item.created_at }
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200">
                                                            <span className="flex flex-row px-6 py-3 items-center justify-between">
                                                                <NavLink to={`/adm/cli/u/${item.id}`}>
                                                                    <FaRegEye className="text-blue-500 hover:text-blue-500"/>
                                                                </NavLink>
                                                                <NavLink to={`/adm/cli/p/${item.id}`}>
                                                                    <FaArchive className="text-green-500 hover:text-green-500" />
                                                                </NavLink>
                                                                {
                                                                    removing !== item.id ?
                                                                        <button onClick={() => remove(item.id)}>
                                                                            <FaTrashAlt className="text-red-500 hover:text-red-500" />
                                                                        </button> :
                                                                        <FaSpinner className="animate-spin text-blue-500" />
                                                                }
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                            <tr>
                                                <td className="border-dashed border-t border-gray-200">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center">
                                                        Sem resultados 
                                                    </span>
                                                </td>
                                            </tr>
                                        ) :
                                        <tr>
                                            <td className="border-dashed border-t border-gray-200">
                                                <span className="flex w-12 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                            </td>
                                            <td className="border-dashed border-t border-gray-200">
                                                <span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                            </td>
                                            <td className="border-dashed border-t border-gray-200">
                                            <   span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                            </td>
                                            <td className="border-dashed border-t border-gray-200">
                                                <span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        { !loading && <Pagination data={client} route="/adm/cli" /> }
                    </div>
                </main>
            </div>
        </div>
    );
}