import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

export const Pagination = (props) => {
	const [pagination, setPagination] = useState([]);

    useEffect(() => {
		function pageLinks () {
			const pageData = props?.data;
			const curretPage = pageData?.current_page;
			let pagReturn = [];

			// Página anterior.
			pagReturn.push({
				label: '<',
				page: curretPage !== 1 ? curretPage - 1 : null,				
			});

			// Páginas comuns.
			for (let index = 1; index <= pageData.last_page; index++) {
				pagReturn.push({
					label: index,
					page: index,
					current: index === curretPage,
					
				});
			}

			// Página posterior.
			pagReturn.push({
				label: '>',
				page: curretPage !== pageData.last_page ? curretPage + 1 : null,				
			});

			setPagination(pagReturn);
		}
        pageLinks();
    }, [props, pagination]);

	
    return props.data.last_page !== 1 &&
		<div className="flex flex-col items-center bg-white px-5 py-5 mt-6 xs:flex-row xs:justify-between">
			<div>
				<nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
					{
						pagination.map((item) => {
							return (
								item.page && (
									!item?.current ?	
										<NavLink to={`${props.route}/${item.page}/`} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-lg text-gray-900 hover:bg-gray-50">
											{item.label}
										</NavLink> :
										<li className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-gray-100 text-lg text-blue-700 hover:bg-gray-200">
											<span>{item.label}</span>
										</li>
								)
							);
						})
					}
				</nav>
			</div>
		</div>
}