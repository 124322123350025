import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';

import { Menu } from '@/components/Menu';
import MenuRouter from '@/components/MenuRouter';

import logo from '@/images/set-construcao-virtual.png';

export const Header = (props) => {
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <header className="fixed top-0 left-0 right-0 bg-black z-40">
            <div className="relative max-w-screen-xl z-10 py-3 sm:pt-6 px-4 sm:px-6 lg:px-8">
                <nav className="relative flex items-center justify-between">
                    <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                        <div className="flex items-center justify-between w-full md:w-auto">
                            <NavLink to="/">
                                <span className="sr-only">Workflow</span>
                                <img src={logo} className="h-12 w-auto sm:h-14 sm:h-8 sm:-mt-3" alt="SET Construção Virtual" />
                            </NavLink>
                            <div className="-mr-2 flex items-center md:hidden">
                                <button type="button" className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset" aria-expanded="false" onClick={() => setOpenMenu(true)}>
                                    <span className="sr-only">Open main menu</span>
                                    <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block md:ml-10">
                        <div className="flex justify-end text-white font-medium mb-3 md:space-x-6">
                            <a className="opacity-75 hover:opacity-100" href="https://www.facebook.com/SET-Projetos-Integrados-472983903060343/" target="_blank" rel="noreferrer">
                                <FaFacebookF />
                            </a>
                            <a className="opacity-75 hover:opacity-100" href="https://www.instagram.com/setconstrucaovirtual/" target="_blank" rel="noreferrer">
                                <FaInstagram />
                            </a>
                            <a className="opacity-75 hover:opacity-100" href="https://br.linkedin.com/company/set-construcao-virtual" target="_blank" rel="noreferrer">
                                <FaLinkedinIn />
                            </a>
                            <a className="opacity-75 hover:opacity-100" href="https://www.youtube.com/channel/UC4jkMAyvMik1DJtxlp-z4Hw" target="_blank" rel="noreferrer">
                                <FaYoutube />
                            </a>
                        </div>
                        { props.routes ? <Menu go={props.routes} /> : <MenuRouter/> }
                    </div>
                </nav>
            </div>
            { openMenu &&
                <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-40">
                    <div className="p-6 rounded-lg shadow-md bg-gray-900 ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="pt-4 flex items-center justify-between">
                            <NavLink to="/">
                                <img src={logo} className="h-8 w-auto sm:h-14 -mt-3" alt="SET Construção Virtual" />
                            </NavLink>
                            <div className="-mr-2">
                                <button type="button" className="fixed top-4 right-4 bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={() => setOpenMenu(false)}>
                                    <span className="sr-only">Close main menu</span>
                                    <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="pt-12 pb-3 text-white">
                            { props.routes ? <Menu go={props.routes} /> : <MenuRouter/> }
                        </div>
                    </div>
                </div>
            }
        </header>
    );
}