import React, { useRef } from 'react';
import { Link } from "react-router-dom";

import { IoDesktopOutline, IoCubeOutline, IoRocketOutline } from 'react-icons/io5';

import { Header } from '@/components/header';
import Footer from '@/components/Footer';

import { PostCard } from '@/components/PostCard';

import videoBg from '@/images/bg-home-casa-set-construcao-virtual.mp4';
import EmpEsp from '@/images/empresa-especialista-set-construcao.jpg';
import BIMSec from '@/images/bim-section-set-construcao.jpg';
import PASec from '@/images/projeto-arquitetonico-set-construcao.jpg';
import PESec from '@/images/projeto-estrutural-set-construcao.jpeg';
import RVSec from '@/images/realidade-virtual-set-construcao.jpg';
import RASec from '@/images/realidade-aumentada-set-construcao.jpg';
import SPSec from '@/images/sistema-predial-set-construcao.jpeg';

// api mockada.
import posts from '@/utils/blog';

export default function HomePage() {
    const menu = {
        home: useRef(),
        quemSomos: useRef(),
        bim: useRef(),
        servicos: useRef(),
        produtos: useRef(),
        blog: useRef(),
        contato: useRef()
    };

    return (
        <div className="relative bg-black">
            <Header routes={menu} />
            <main>
                <section ref={menu.home} className="relative flex items-center justify-center min-h-screen pt-32 pb-48 bg-gray-800">
                    <div className="relative z-10 mt-24">
                        <div className="text-center">
                            <h2 className="mt-6 text-4xl font-bold text-white md:text-5xl">Expert em <br /><span className="text-primary">Construção Virtual</span></h2>
                            <div className="flex justify-center mt-8">
                                <a className="px-8 py-4 text-base font-bold uppercase text-black transition-colors duration-300 transform bg-primary rounded cursor-pointer" href="https://api.whatsapp.com/send?phone=5581971141234&text=Vim%20pelo%20site%20e%20tenho%20algumas%20duvidas" target="_blank" rel="noreferrer" >
                                    Solicitar orçamento
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 w-full opacity-40">
                        <video autoPlay="autoplay" loop="loop" muted className="h-full w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full">
                            <source src={videoBg} type="video/mp4" />
                            Seu navegador nao suporta tags de video.
                        </video>
                    </div>
                </section>
                <section ref={menu.quemSomos} className="relative grid bg-black">
                    <div className="relative flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
                        <div className="relative flex sm:w-1/2 bg-gray-800 text-white w-full h-full flex-auto items-center justify-center overflow-hidden">
                            <img className="object-cover w-full h-full" src={EmpEsp} alt="" />
                        </div>
                        <div className="flex items-center justify-left w-full w-auto h-full sm:w-1/2 p-8 md:p-10 lg:p-14">
                            <div className="max-w-xl w-full py-12 space-y-12">
                                <div className="lg:text-left text-center">
                                    <h2 className="relative text-5xl font-bold text-white sm:-ml-32">
                                        Empresa<br/>
                                        <span className="text-primary">especialista</span><br/>
                                        de <span className="text-primary">arquitetura</span><br/>
                                        e <span className="text-primary">tecnologia</span>.
                                    </h2>
                                    <span className="block w-32 h-2 bg-primary mx-auto sm:ml-0 mt-4" />
                                    <p className="mt-6 text-base text-gray-200">Somos uma empresa de projetos de Arquitetura, Sistemas Prediais e Estruturais, Planejamento de Pré-construção e construção em Realidade Virtual. Atuamos nesses nichos desde 2014 utilizando a tecnologia BIM no desenvolvimento de projetos para construção civil e incorporação imobiliária.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section ref={menu.bim} className="relative grid bg-black sm:pt-24">
                    <div className="relative flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
                        <div className="relative flex sm:w-1/2 sm:order-last bg-gray-800 w-full h-full overflow-hidden">
                            <img className="object-cover w-full h-full" src={BIMSec} alt="" />
                        </div>
                        <div className="flex items-center justify-left w-full w-auto h-full sm:w-1/2 p-8">
                            <div className="max-w-xl w-full space-y-12">
                                <div className="text-center sm:text-right">
                                    <div className="relative p-10 sm:-mr-32 bg-black">
                                        <h2 className="text-5xl font-bold text-primary">
                                            <span className="text-white">(BIM)</span><br/>
                                            Building<br/>
                                            Information<br/>
                                            Modeling
                                        </h2>
                                        <span className="block w-32 h-2 bg-primary mx-auto sm:mr-0 mt-4" />
                                    </div>
                                    <p className="mt-6 text-base text-gray-200">Usando a tecnologia BIM - Building Information Modeling, ou Modelagem da Informação da Construção - a SET entrega modelos virtuais bastante precisos da construção. Unindo a arquitetura, estrutura e sistemas prediais em um único modelo inteligente, prático e funcional, onde garantimos a compatibilidade perfeita entre os detalhes que compõem a obra, geramos dados que dão suporte ao projeto durante a execução de suas fases e permitem uma análise precisa e o total controle de todo o processo de construção do seu empreendimento, desde seu planejamento até toda a construção.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section ref={menu.servicos} className="relative bg-black text-white pt-24">
                    <div className="max-w-6xl m-auto">
                        <div className="text-center sm:text-right sm:mb-20">
                            <h2 className="text-5xl font-bold text-primary">
                                O que<br/>
                                <span className="text-white">Fazemos</span>
                            </h2>
                            <span className="block w-32 h-2 bg-primary mx-auto sm:mr-0 mt-4" />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-20">
                            <div className="text-center text-xl uppercase space-y-3 sm:-mt-44">
                                <div className="bg-gray-800">
                                    <img className="object-cover w-full h-96" src={PASec} alt="" />
                                </div>
                                <h3>Projeto<br/>Arquitetônico</h3>
                            </div>
                            <div className="text-center text-xl uppercase space-y-3 sm:-mt-24">
                                <div className="bg-gray-800">
                                    <img className="object-cover w-full h-96" src={PESec} alt="" />
                                </div>
                                <h3>Projeto<br/>Estrutural</h3>
                            </div>
                            <div className="text-center text-xl uppercase space-y-3">
                                <div className="bg-gray-800">
                                    <img className="object-cover w-full h-96" src={SPSec} alt="" />
                                </div>
                                <h3>Projeto de<br/>sistemas prediais</h3>
                            </div>
                            <div className="text-center sm:text-right order-first sm:order-none mb-20">
                                <div className="hidden sm:block">
                                    <h2 className="text-5xl font-bold text-primary">
                                        Como<br/>
                                        <span className="text-white">Fazemos</span>
                                    </h2>
                                    <span className="block w-32 h-2 bg-primary mx-auto sm:mr-0 mt-4" />
                                </div>
                                <p className="mt-6 text-base text-gray-200">Trabalhamos com as melhores tecnologias para revolucionar o mercado de arquitetura, engenharia, construção e operação.</p>
                            </div>
                            <div className="text-center text-xl uppercase space-y-3 sm:-mt-24">
                                <div className="bg-gray-800">
                                    <img className="object-cover w-full h-96" src={RVSec} alt="" />
                                </div>
                                <h3>Realidade Virtual</h3>
                            </div>
                            <div className="text-center text-xl uppercase space-y-3">
                                <div className="bg-gray-800">
                                    <img className="object-cover w-full h-96" src={RASec} alt="" />
                                </div>
                                <h3>Realidade Aumentada</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section ref={menu.produtos} className="relative bg-produtos bg-cover bg-bottom text-white mt-24 py-32">
                    <span className="absolute inset-0 bg-black bg-opacity-30" />
                    <div className="relative max-w-5xl mx-auto">
                        <div className="text-center mb-12">
                            <h2 className="text-5xl font-bold text-primary">
                                Produtos <span className="text-white">SET</span>
                            </h2>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:pb-24">
                            <div className="p-4 text-center border rounded-lg">
                                <div>
                                    <IoDesktopOutline className="text-primary text-5xl mx-auto" />
                                    <h4 className="text-4xl font-bold mt-4 mb-2">
                                        <span className="text-primary">SET</span> MOL
                                    </h4>
                                </div>
                                <p className="text-base text-white">Modelagem e Compatibilização de Projetos</p>
                            </div>
                            <div className="p-4 text-center border rounded-lg">
                                <div>
                                    <IoCubeOutline className="text-primary text-5xl mx-auto" />
                                    <h4 className="text-4xl font-bold mt-4 mb-2">
                                        <span className="text-primary">SET</span> PROJ
                                    </h4>
                                </div>
                                <p className="text-base text-white">Projetos Autorais</p>
                            </div>
                            <div className="p-4 text-center border rounded-lg">
                                <div>
                                    <IoRocketOutline className="text-primary text-5xl mx-auto" />
                                    <h4 className="text-4xl font-bold mt-4 mb-2">
                                        <span className="text-primary">SET</span> BIM
                                    </h4>
                                </div>
                                <p className="text-base text-white">Implementação BIM</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section ref={menu.blog} className="relative text-white sm:py-24">
                    <div className="max-w-5xl mx-auto">
                        <div className="text-center mb-12">
                            <h2 className="text-5xl font-bold text-white">
                                Nosso <span className="text-primary">Blog</span>
                            </h2>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {posts.slice(0, 3).map((item) => {
                                return (
                                    <PostCard data={item} />
                                )
                            })}
                        </div>
                        <div className="text-center mt-14">
                            <Link to={`/blog/`} className="text-black font-bold text-sm tracking-wide uppercase bg-primary py-3 px-8 rounded-3xl">Ver Mais</Link>
                        </div>
                    </div>
                </section>
                <section ref={menu.contato}>
                    <Footer />
                </section>
            </main>
        </div>
    );
}