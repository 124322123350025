import React from 'react';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '@/lib/useAuth';

import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export default function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename="/">
          <Routes />
        </BrowserRouter>
      </QueryClientProvider>
    </AuthProvider>
  );
}
