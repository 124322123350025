const cases = [
    {
        slug: 'aba',
        title: 'ABA',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/aba-case.jpg',
        description: '01 Reforma<br/>01 novo prédio<br/>4500 m2<br/>Sistemas Prediais<br/>Av. Santos Dumont.',
    },
    {
        slug: 'casa-sustentavel',
        title: 'Casa Sustentável',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/casa-sustentavel-case.jpg',
        description: 'Casa totalmente  industrializada<br/>Sistemas Prediais<br/>50m2<br/>SINDUSCON-PE',
    },
    {
        slug: 'residencial-ecolife',
        title: 'Residencial Ecolife',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/residencial-ecolife-case.jpg',
        description: 'Porto Neto Engenharia<br/>Sistemas Prediais<br/>Modelagem e Compatibilização<br/>850m2<br/>Olinda',
    },
    {
        slug: 'rpm',
        title: 'RPM',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/rpm-case.jpg',
        description: 'Modelagem e Compatibilização<br/>9.000,00m2<br/>Casa Grande Engenharia',
    },
    {
        slug: 'tenorio-simoes',
        title: 'Tenório Simões',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/tenorio-simoes-case.jpg',
        description: 'Residencial Minha Casa Minha Vida<br/>Sistemas Prediais,<br/>Modelagem e<br/>Compatibilização.<br/>2.000 m2<br/>Paulista',
    },
    {
        slug: 'casa-brasilis',
        title: 'Casa Brasilis',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/casa-brasilis-case.jpg',
        description: 'Sistemas Prediais, modelagem e compatibilização<br/>300m2<br/>GRAVATÁ-PE',
    },
    {
        slug: 'apolo',
        title: 'Apolo',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/apolo-case.jpg',
        description: 'C.E.S.A.R.<br/>ELÉTRICA/PCI<br/>Modelagem e Compatibilização<br/>2.000 m2<br/>Rua do Apolo',
    },
    {
        slug: 'neue',
        title: 'NEUE',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/neue-case.jpg',
        description: 'Sistemas Prediais<br/>Modelagem e Compatibilização<br/>2.000,00m2<br/>HAUT',
    },
    {
        slug: 'incorporacao',
        title: 'Incorporação',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/incorporacao-case.jpg',
        description: 'REIS EMPREENDIMENTOS<br/>Modelagem<br/>Compatibilizção<br/>9.000,00m2',
    },
    {
        slug: 'bureau-veritas',
        title: 'Bureau Veritas',
        thumbnail: 'https://setconstrucaovirtual.com.br/img/cases/bureau-veritas-case.jpg',
        description: 'Restaurante<br/>Facility Manager<br/>2.000,00m2<br/>HAUT',
    },
];

export default cases;