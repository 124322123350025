import React from 'react';
import api from '@/services/api';
import { useMutation } from 'react-query';
import { getAuth, setAuth } from '@/lib/auth';

const initialState = {
    ...getAuth(),
    isLoading: false,
    isError: false,
}

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN_START':
            return {
                ...state,
                user: null,
                token: null,
                isLoading: true,
                isError: false,
            };
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                user: action.user,
                token: action.token,
                isLoading: false,
                isError: false,
            };
        case 'LOGIN_FAILURE':
            return {
                ...state,
                user: null,
                token: null,
                isLoading: false,
                isError: true,
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                token: null,
                isLoading: false,
                isError: false,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
            
    }
}

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(authReducer, initialState);
    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useUser = () => {
    const { state } = React.useContext(AuthContext);
    return { user: state.user, isLoading: state.isLoading, token: state.token };
}

export const useAuth = () => {
    const { state, dispatch } = React.useContext(AuthContext);
    const { mutate, ...rest } = useMutation(async({ email, password }) => {
        dispatch({ type: 'LOGIN_START'});
        
        const data = new FormData();
        data.append('email', email);
        data.append('password', password);

        let response;
        try {
            response = await api.post('/login', data);
            dispatch({
                type: 'LOGIN_SUCCESS',
                user: response.data.user,
                token: response.data.token,
            });

            setAuth({ token: response.data.token, user: response.data.user });
        }
        catch (error) {
            dispatch({ type: 'LOGIN_FAILURE' });
            response = null;
        }

        return response.data;
    });

    const loginWithEmail = (email, password) => {
        if (state.isLoading) return;
        mutate({ email, password });
    };

    const logout = () => {
        setAuth({ token: null, user: null });
        dispatch({ type: 'LOGOUT' });
    }

    return { loginWithEmail, logout, ...rest };
};