import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

export const AlertBox = (props) => {
    const [openAlert, setOpenAlert] = useState(props.open);

    useEffect(() => {
        setOpenAlert(props.open);
    }, [props.open]);

    return (
        openAlert &&
            <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div onClick={props.close} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className="inline-block align-bottom w-full max-w-sm bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                        <div className="bg-white p-8 pb-4">
                            <div className="block">
                                { 
                                    !props.error ?
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
                                            <FaCheck className="text-2xl text-green-600" />
                                        </div> :
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-red-100 mb-6">
                                            <FaTimes className="text-2xl text-red-600" />
                                        </div>
                                }
                                <div className="mt-3 mb-2 text-center">
                                    <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                                        {props.title}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 p-4 pt-4 text-center">
                            <button onClick={props.close} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white m-0 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm">
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export const AlertBoxOpts = (props) => {
    const [openAlert, setOpenAlert] = useState(props.open);

    useEffect(() => {
        setOpenAlert(props.open);
    }, [props.open]);
    
    return (
        openAlert &&
            <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div onClick={props.close} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                        <div className="bg-white p-8 pb-4">
                            <div className="block">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
                                    <FaCheck className="text-2xl text-green-600" />
                                </div>
                                <div className="mt-3 mb-2 text-center">
                                    <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                                        {props.title}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 p-4 pt-4 text-center">                        
                            <button onClick={props.close} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                                Cancelar
                            </button>
                            <button onClick={props.close} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    );
}