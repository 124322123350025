let _token = localStorage.getItem('token');
let _user = JSON.parse(localStorage.getItem('user'));

export const getAuth = () => {
    return { token: _token, user: _user };
}

export const setAuth = ({ token, user }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', user ? JSON.stringify(user) : null);

    _token = token;
    _user = user;
}