import React from 'react';
import { Link, NavLink } from "react-router-dom";

export const ProjCard = (props) => {
    return (
        <Link to={props.data.slug}>
            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
                <div className="bg-gray-100 h-40">
                    <img
                        src={props.data.thumbnail}
                        className="w-full h-full object-cover"
                        alt={props.data.title}
                    />
                </div>
                <div className="bg-white">
                    <div className="py-5 px-5">
                        <span className="font-bold text-gray-800 text-lg">
                            {props.data.title}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export const ProjAdmCard = (props) => {
    return (
        <NavLink to={`/adm/proj/p/${props.data.id}`}>
            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
                <div className="bg-gray-100 h-40">
                    <img
                        src={props.data.thumbnail}
                        className="w-full h-full object-cover"
                        alt={props.data.title}
                    />
                </div>
                <div className="bg-white">
                    <div className="block py-4 px-5">
                        <span className="flex font-bold text-gray-800 text-lg">
                            {props.data.title}
                        </span>
                        <NavLink to={`/adm/cli/u/${props.data.id_client}`} className="text-gray-400 font-bold">
                            {props.data.client?.name}
                        </NavLink>
                    </div>
                </div>
            </div>
        </NavLink>
    );
}

export const ProjCardLoad = () => {
    return (
        <div className="bg-white overflow-hidden shadow-lg rounded-lg">
            <div className="h-40 bg-gray-100 animate-pulse"></div>
            <div className="bg-white">
                <div className="py-5 px-5">
                    <span className="flex w-36 h-5 bg-gray-200 animate-pulse" />
                </div>
            </div>
        </div>
    );
}