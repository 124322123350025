import React from 'react';
import { PrivateSidebar } from '@/components/PrivateSidebar';
import { FaCheck } from 'react-icons/fa';

export default function ProfileAdmPage() {
    return (
        <div>
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white text-black">            
                <PrivateSidebar userType="ADMIN" />
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="flex flex-wrap items-center mb-12">
                            <div className="text-center sm:text-left">
                                <h2 className="text-3xl font-bold">
                                    Meu <span className="text-primary">Perfil</span>
                                </h2>
                            </div>
                            <div className="flex-grow flex-1 text-right">
                                <button className="flex items-center ml-auto bg-green-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button">
                                    <FaCheck className="mr-2" /> Salvar Perfil
                                </button>
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0 break-words w-full">
                            <form>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Nome
                                        </label>
                                        <input type="text" className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2" />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Email
                                        </label>
                                        <input type="email" className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2" />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Mudar senha
                                        </label>
                                        <input type="text" className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}