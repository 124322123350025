import React from 'react';
import { NavLink } from "react-router-dom";
import { FaArrowUp, FaWhatsapp, FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';

import logo from '@/images/set-construcao-virtual.png';

export default function Footer() {
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <footer className="bg-black pt-10 sm:mt-10 pt-10">
            <div onClick={scrollToTop} className="fixed bottom-16 right-6 text-4xl text-white opacity-70 hover:opacity-100 cursor-pointer z-30">
                <FaArrowUp />
            </div>
            <div className="fixed bottom-0 right-4 bg-primary font-bold px-4 py-2 rounded-t-lg z-30">
                <a className="flex" href="https://api.whatsapp.com/send?phone=5581971141234&text=Vim%20pelo%20site%20e%20tenho%20algumas%20duvidas" target="_blank" rel="noreferrer" >
                    <FaWhatsapp className="mr-2 my-auto" />
                    Vamos conversar por Whatsapp?
                </a>
            </div>
            <div className="max-w-6xl m-auto grid grid-cols-1 sm:grid-cols-4 gap-4 text-gray-800 justify-left">
                <div className="p-5">
                    <NavLink to="/">
                        <span className="sr-only">Workflow</span>
                        <img src={logo} className="h-8 w-auto sm:h-14" alt="SET Construção Virtual" />
                    </NavLink>
                </div>
                <div className="p-5">
                    <h4 className="text-2xl text-white font-bold mb-6">
                        Contato
                    </h4>
                    <a href="callto:5581971141234" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                        (81) 97114-1234
                    </a>
                    <a href="mailto:contato@setconstrucaovirtual.com.br" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                        contato@setconstrucaovirtual.com.br
                    </a>
                </div>
                <div className="p-5">
                    <h4 className="text-2xl text-white font-bold mb-6">
                        Endereço
                    </h4>
                    <p className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                        Rua Antônio Lumack do Monte, 96<br/>
                        Sala 701, Center 3<br/>
                        Boa Viagem, Recife - PE.<br/>
                        CEP: 51020-280
                    </p>
                </div>
                <div className="p-5">
                    <h4 className="text-2xl text-white font-bold mb-6">
                        Redes sociais
                    </h4>
                    <a href="https://www.facebook.com/SET-Projetos-Integrados-472983903060343/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700" target="_blank" rel="noreferrer">
                        Facebook
                    </a>
                    <a href="https://www.instagram.com/setconstrucaovirtual/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700" target="_blank" rel="noreferrer">
                        Instagram
                    </a>
                    <a href="https://br.linkedin.com/company/set-construcao-virtual" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700" target="_blank" rel="noreferrer">
                        Linkedin
                    </a>
                    <a href="https://www.youtube.com/channel/UC4jkMAyvMik1DJtxlp-z4Hw" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700" target="_blank" rel="noreferrer">
                        YouTube
                    </a>
                </div>
            </div>
            <div className="pt-2">
                <div className="flex pb-5 px-3 m-auto pt-5 
                    border-t border-gray-500 text-gray-400 text-sm 
                    flex-col md:flex-row max-w-6xl">
                    <div className="mt-2">
                        © Copyright 2021 SET Construção virtual. Todos os direitos reservados.
                    </div>
                    <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex space-x-1">
                        <a href="https://www.facebook.com/SET-Projetos-Integrados-472983903060343/" className="w-6" target="_blank" rel="noreferrer">
                            <FaFacebookF />
                        </a>
                        <a href="https://www.instagram.com/setconstrucaovirtual/?igshid=6dtiqsccnho" className="w-6" target="_blank" rel="noreferrer">
                            <FaInstagram />
                        </a>
                        <a href="https://br.linkedin.com/company/set-construcao-virtual" className="w-6" target="_blank" rel="noreferrer">
                            <FaLinkedinIn />
                        </a>
                        <a href="https://www.youtube.com/channel/UC4jkMAyvMik1DJtxlp-z4Hw" className="w-6" target="_blank" rel="noreferrer">
                            <FaYoutube />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}