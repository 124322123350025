import React, { useEffect, useState } from 'react';
import { selfRegister } from '@/lib/clients';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";

import { FaSpinner } from 'react-icons/fa';
import logo from '@/images/set-construcao-virtual.png';

export default function Signin() {
    let goesTo = useHistory();
    const { register, handleSubmit } = useForm();
    const [sending, setSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    
    const onSubmit = (formState) => {
        async function sendRequest() {
            setSending(true);
            const result = await selfRegister(formState);
            setSending(false);
            if (!result) return setIsError(true);
            console.log('result', result);
            setIsSuccess(result.success);
        }
        sendRequest(); 

    };

    useEffect(() => {
        if (isSuccess) goesTo.push("/cli/proj");
    });

    return (
        <div className="w-full h-screen flex items-center justify-center bg-gray-800">
            <div className="lg:w-2/5 md:w-1/2 w-2/3">
                <img src={logo} className="h-auto w-46 mx-auto" alt="SET Construção Virtual" />
                <form className="p-10 rounded-lg shadow-lg min-w-full" onSubmit={handleSubmit(onSubmit)}>
                    {
                        isError &&
                        <span className="flex bg-red-500 border-red-600 p-4 border-2 rounded-md">
                            <div className="mx-auto">
                                <h6 className="text-sm font-bold text-white">
                                    Não foi possível concluir o cadastro
                                </h6>
                            </div>
                        </span>
                    }
                    <div>
                        <label
                            className="text-white font-semibold block my-3 text-md"
                            for="nome"
                        >
                            Nome
                        </label>
                        <input
                            className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                            type="text"
                            id="nome" 
                            {...register('name', { required: true })}
                        />
                    </div>
                    <div>
                        <label
                            className="text-white font-semibold block my-3 text-md"
                            for="email"
                        >
                            Email
                        </label>
                        <input
                            className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                            type="text"
                            id="email" 
                            placeholder="Email"
                            {...register('email', { required: true })}
                        />
                    </div>
                    <div>
                        <label
                            className="text-white font-semibold block my-3 text-md"
                            for="password"
                        >
                            Senha
                        </label>
                        <input
                            className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                            type="password"
                            id="password" 
                            placeholder="Senha"
                            {...register('password', { required: true })}
                        />
                    </div>
                    <div>
                        <label
                            className="text-white font-semibold block my-3 text-md"
                            for="phone"
                        >
                            Telefone
                        </label>
                        <input
                            className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                            type="text"
                            id="telefone" 
                            {...register('phone', { required: true })}
                        />
                    </div>
                    {
                        !sending ?
                            <button
                                type="submit"
                                className="w-full mt-6 bg-indigo-600 rounded-lg px-4 py-2 text-lg text-white tracking-wide font-semibold font-sans">
                                Cadastrar
                            </button> :
                            <button
                                type="button"
                                className="flex items-center justify-center w-full mt-6 bg-indigo-600 rounded-lg px-4 py-2 text-lg text-white tracking-wide font-semibold font-sans"
                                disabled
                                >
                                <FaSpinner className="animate-spin mr-2" /> Registrando...
                            </button>
                    }
                </form>
            </div>
        </div>
    );
}