import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import emptyPlace from '@/images/empty-results.png';

import { getUserProjetos } from '@/lib/projetos';
import { FaPlusCircle } from 'react-icons/fa';

import { PrivateSidebar } from '@/components/PrivateSidebar';
import { Pagination } from '@/components/Pagination';
import { ProjCard, ProjCardLoad } from '@/components/Cards/ProjCard';

export default function ProjUserAdmPage() {
    const { page } = useParams();
    const [projs, setProjs] = useState([]);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        async function Init() {
            const response = await getUserProjetos(id, page ?? 1);
            setProjs(response);
            setLoading(false);
        }
        Init();
    }, [id, page]);

    return (
        <div>
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
                <PrivateSidebar userType="ADMIN"/>
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="flex flex-wrap items-center mb-8">
                            <div className="text-center sm:text-left">
                                <h2 className="text-3xl font-bold">
                                    Todos os <span className="text-primary"> Projetos de </span>
                                </h2>
                            </div>
                            <div className="flex-grow flex-1 text-right">
                                <button className="flex items-center ml-auto bg-indigo-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button">
                                    <FaPlusCircle className="mr-2"/>Criar Projeto
                                </button>
                            </div>
                        </div>
                        {
                            !loading ? (
                                projs.data.length ?
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                    {
                                        projs.data.map((item) => {
                                            return (
                                                <ProjCard data = { item } />
                                            )
                                        })
                                    }
                                </div> :
                                <div className="text-center mx-auto">
                                    <div className="relative w-40 h-40 mx-auto mt-24">
                                        <img src={ emptyPlace } className="relative w-full h-full z-10" alt="" />
                                        <span className="flex absolute -left-1/4 -top-1/4 -ml-30 w-60 h-60 bg-gray-100 justify-center rounded-full" />
                                    </div>
                                    <div className="py-4 mt-12">
                                        <h3 className="font-bold text-2xl">Sem resultados</h3>
                                    </div>
                                </div>
                            ) :
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                <ProjCardLoad />
                                <ProjCardLoad />
                                <ProjCardLoad />
                                <ProjCardLoad />
                            </div>
                        }
                        { !loading && <Pagination data={projs} route={`/adm/cli/p/${id}`} /> }
                    </div>
                </main>
            </div>
        </div>
    );
}