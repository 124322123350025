import React from 'react';

import MenuAdm from './MenuAdm';
import MenuCliente from './MenuCliente';

export const PrivateSidebar = (props) => {
    return (
        <div className="fixed flex flex-col top-0 left-0 w-14 hover:w-64 md:w-64 bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar">
            <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
                {props.userType === 'ADMIN' ? <MenuAdm /> : <MenuCliente />}
                <p className="mb-14 px-5 py-3 hidden md:block text-center text-xs">Copyright @2021</p>
            </div>
        </div>
    );
}