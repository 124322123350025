import React, { useEffect } from 'react';
import { useAuth, useUser } from '@/lib/useAuth';
import { useForm } from 'react-hook-form';
import { useHistory, NavLink } from "react-router-dom";

import { FaSpinner } from 'react-icons/fa';
import logo from '@/images/set-construcao-virtual.png';

export default function LoginPage() {
    let goesTo = useHistory();
    const { user } = useUser();
    const { register, handleSubmit } = useForm();
    const { loginWithEmail, isLoading, isSuccess, isError } = useAuth();
    
    const onSubmit = (formState) => {
        loginWithEmail(formState.email, formState.password);
    };

    async function redirectUserAfterLogin() {
        user.tipo === 'ADMIN' ? goesTo.push("/adm/dash") : goesTo.push("/cli/proj");
    }

    useEffect(() => {
        if (user || isSuccess) redirectUserAfterLogin();
    });

    return (
        <div className="w-full h-screen flex items-center justify-center bg-gray-800">
            <div className="flex flex-col items-center">
                <img src={logo} className="h-auto w-46" alt="SET Construção Virtual" />
                <form className="mt-8 w-96 h-auto" onSubmit={handleSubmit(onSubmit)}>
                    {
                        isError &&
                        <span className="flex bg-red-500 border-red-600 p-4 border-2 rounded-md mb-3">
                            <div className="mx-auto">
                                <h6 className="text-sm font-bold text-white">Acessos inválidos</h6>
                            </div>
                        </span>
                    }
                    <input
                        type="text"
                        className="w-full h-12 rounded-lg px-4 text-lg focus:ring-blue-600 mb-4"
                        placeholder="Email"
                        {...register('email', { required: true })}
                    />
                    <input
                        type="password"
                        className="w-full h-12 rounded-lg px-4 text-lg focus:ring-blue-600"
                        placeholder="Senha"
                        {...register('password', { required: true })}
                    />
                    <p className="text-right text-white mt-2 mb-4">Esqueci minha senha</p>
                    { isLoading ? 
                        <button
                            className="w-full h-12 rounded-lg bg-blue-800 text-gray-200 uppercase font-semibold hover:bg-blue-700 text-gray-100 transition mb-4"
                            disabled
                        >
                            <FaSpinner className="animate-spin mr-2" /> Acessando...
                        </button> :
                        <button
                            type="submit"
                            className="w-full h-12 rounded-lg bg-blue-600 text-gray-200 uppercase font-semibold hover:bg-blue-700 text-gray-100 transition mb-4"
                            >
                            Entrar
                        </button>
                    }
                </form>
                <div className="flex text-white">
                    <p>Não possui cadastro?</p>
                    <NavLink
                        to="/register"
                        className="text-primary ml-2"
                    >
                        Cadastre-se
                    </NavLink>
                </div>
            </div>
        </div>
    );
}