import React, { useState, useEffect } from 'react';
import { PrivateSidebar } from '@/components/PrivateSidebar';

import { getDash } from '@/lib/dash';

export default function DashAdmPage() {
    const [widgets, setwidgets] = useState([]);
    const [client, setClient] = useState([]);
    const [projs, setProjs] = useState([]);
    const [loading, setLoading] = useState(true);

    async function init() {
        const list = await getDash();
        setwidgets(list.widgets);
        setClient(list.clients);
        setProjs(list.projects);
        setLoading(false);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">            
                <PrivateSidebar userType="ADMIN" />
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                            <div className="p-5 bg-white rounded-lg shadow-lg">
                                <div className="text-sm font-bold uppercase text-gray-400">Projetos</div>
                                <div className="flex items-center pt-1">
                                    <div className="text-2xl font-bold text-gray-900">{widgets.projects}</div>
                                </div>
                            </div>
                            <div className="p-5 bg-white rounded-lg shadow-lg">
                                <div className="text-sm font-bold uppercase text-gray-400">Clientes</div>
                                <div className="flex items-center pt-1">
                                    <div className="text-2xl font-bold text-gray-900">{widgets.clients}</div>
                                </div>
                            </div>
                            <div className="p-5 bg-white rounded-lg shadow-lg">
                                <div className="text-sm font-bold uppercase text-gray-400">xxxx</div>
                                <div className="flex items-center pt-1">
                                    <div className="text-2xl font-bold text-gray-900">1</div>
                                </div>
                            </div>
                            <div className="p-5 bg-white rounded-lg shadow-lg">
                                <div className="text-sm font-bold uppercase text-gray-400">xxxx</div>
                                <div className="flex items-center pt-1">
                                    <div className="text-2xl font-bold text-gray-900">1</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-2 mb-5">
                            <div className="">
                                <h3 className="text-lg font-bold mb-4">Clientes</h3>
                                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Nome </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Email </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Criado em </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !loading ? (
                                                client.length ?
                                                    client.slice(0, 5).map((item) => {
                                                        return (
                                                        <tr key={item.id}>
                                                            <td className="border-dashed border-t border-gray-200">
                                                                <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                    { item.name }
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200">
                                                                <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                    { item.email }
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200">
                                                                <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                    { item.created_at }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )}) :
                                                    <tr>
                                                        <td className="border-dashed border-t border-gray-200">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                Sem resultados 
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) :
                                                <tr>
                                                    <td className="border-dashed border-t border-gray-200">
                                                        <span className="flex w-12 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200">
                                                        <span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200">
                                                    <   span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200">
                                                        <span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                                    </td>
                                                </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="">
                                <h3 className="text-lg font-bold mb-4">Projetos</h3>
                                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Nome </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Cliente </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"> Criado em </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !loading ? (
                                                projs.length ?
                                                    projs.slice(0, 5).map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="border-dashed border-t border-gray-200">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                        { item.title }
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                        { item.client?.name ?? 'Sem cliente'}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                        { item.created_at }
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td className="border-dashed border-t border-gray-200">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center">
                                                                Sem resultados 
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) :
                                                <tr>
                                                    <td className="border-dashed border-t border-gray-200">
                                                        <span className="flex w-12 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200">
                                                        <span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200">
                                                    <   span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200">
                                                        <span className="flex w-36 h-5 mx-6 my-3 bg-gray-200 animate-pulse" />
                                                    </td>
                                                </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}