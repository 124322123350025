import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PrivateSidebar } from '@/components/PrivateSidebar';
import { AlertBox } from '@/components/Alerts';

import { FaRegTrashAlt, FaRegSave, FaSpinner } from 'react-icons/fa';

import { viewProjeto, updateProjeto, deleteProjeto } from '@/lib/projetos';
import { getClients } from '@/lib/clients';

export default function ProjSingleAdmPage() {
    let goesTo = useHistory();
    const { id } = useParams();
    const { setValue, getValues, register, handleSubmit } = useForm();
    
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [sending, setSending] = useState(false);

    const onSubmit = (formState) => {
        console.log('formState', formState);
        async function sendRequest() {
            setSending(true);
            const result = await updateProjeto(formState, id);
            setSending(false);
            setAlertTitle("Projeto atualizado");
            if (result.success) setAlert(true);
        }
        sendRequest();
    };

    async function remove() {
        const response = await deleteProjeto(id);
        setAlertTitle("Projeto removido");
        if (response.success) setAlert(true);
    };

    function endDel() {
        setAlert(false);
        goesTo.push(`/adm/proj`);
    }

    useEffect(() => {
        async function init() {
            const response = await viewProjeto(id);
            setValue('title', response.title);
            setValue('url', response.url);
            setValue('id_client', response.id_client);
            setClients(await getClients());
            setLoading(false);
        }
        init();
    }, [id, setValue]);

    return (
        <div>
            <AlertBox title={alertTitle} open={alert} close={() => endDel()} />
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white text-black">            
                <PrivateSidebar userType="ADMIN"/>
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="flex flex-wrap items-center mb-12">
                            <div className="text-center sm:text-left">
                                <h2 className="text-3xl font-bold">
                                    Dados do <span className="text-primary">Projeto</span>
                                </h2>
                            </div>
                            <div className="flex flex-row text-right ml-auto">
                                <button onClick={remove} className="flex items-center bg-red-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1">
                                    <FaRegTrashAlt className="mr-2" /> Deletar
                                </button>
                                {
                                    !sending ?
                                        <button onClick={handleSubmit(onSubmit)} className="flex items-center ml-auto bg-green-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1">
                                            <FaRegSave className="mr-2" /> Salvar
                                        </button> :
                                        <button className="flex items-center bg-blue-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1" disabled>
                                            <FaSpinner className="animate-spin mr-2" /> Enviando
                                        </button>
                                }
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0 break-words w-full">
                            <form className="w-full sm:w-96">
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Nome
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('title', { required: true })}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Url
                                        </label>
                                        <input
                                            type="text"                                            
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('url', { required: true })}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Thumbnail
                                        </label>
                                        <input
                                            type="file"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('thumbnail')}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Cliente
                                        </label>
                                        <select
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('id_client', { required: true })}
                                            >
                                            {
                                                !loading && (
                                                    clients.length &&
                                                        clients.map((item) => {
                                                            return (
                                                                <option value={item.id} selected={getValues('id_client') === item.id}>{item.name}</option>
                                                            )
                                                        })
                                                    )
                                            }
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}