// import React, { useState } from 'react';
import React from 'react';
import { NavLink } from "react-router-dom";
import { useAuth } from '@/lib/useAuth';
import { FiHome, FiPackage, FiUser, FiLogOut } from 'react-icons/fi';

export default function MenuAdm() {
    const { logout } = useAuth();
    return (
        <ul className="flex flex-col py-4 space-y-1">
            <li className="px-5 hidden md:block">
                <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light tracking-wide text-gray-400 uppercase">Módulos</div>
                </div>
            </li>
            <li>
                <NavLink to="/adm/dash" activeClassName="bg-gray-800" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-800 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-gray-500 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <FiHome className="w-5 h-5" />
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Dashboard</span>
                </NavLink>
            </li>
            <li>
                <NavLink to="/adm/proj" activeClassName="bg-gray-800" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-800 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-gray-500 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <FiPackage className="w-5 h-5" />
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Projetos</span>
                </NavLink>
            </li>
            <li>
                <NavLink to="/adm/cli" activeClassName="bg-gray-800" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-800 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-gray-500 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <FiPackage className="w-5 h-5" />
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Clientes</span>
                </NavLink>
            </li>
            <li className="px-5 hidden md:block">
                <div className="flex flex-row items-center mt-5 h-8">
                    <div className="text-sm font-light tracking-wide text-gray-400 uppercase">Ajustes</div>
                </div>
            </li>
            <li>
                <NavLink to="/adm/perf" activeClassName="bg-gray-800" className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-800 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-gray-500 pr-6">
                    <span className="inline-flex justify-center items-center ml-4">
                        <FiUser className="w-5 h-5" />
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Meu Perfil</span>
                </NavLink>
            </li>
            <li>
                <button
                    className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-800 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-gray-500 pr-6"
                    onClick={() => logout()}
                >
                    <span className="inline-flex justify-center items-center ml-4">
                        <FiLogOut className="w-5 h-5" />
                    </span>
                    <span className="ml-2 text-sm tracking-wide truncate">Sair</span>
                </button>
            </li>
        </ul>
    );
}