import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AlertBox } from '@/components/Alerts';
import { PrivateSidebar } from '@/components/PrivateSidebar';

import { FaRegTrashAlt, FaRegSave, FaSpinner } from 'react-icons/fa';
import { viewClient, updateClient, removeClient, SearchByCep } from '@/lib/clients';

export default function ClientSingleAdmPage() {
    const { id } = useParams();
    const { setValue, register, handleSubmit } = useForm();

    const [alert, setAlert] = useState(false);
    const [sending, setSending] = useState(false);

    const onSubmit = (formState) => {
        async function sendRequest() {
            setSending(true);
            const result = await updateClient(formState, id);
            setSending(false);
            if (result.success) setAlert(true);
        }
        sendRequest();
    };

    async function cepFill(cep) {
        if (cep.length !== 8 ) return;
        const location = await SearchByCep(cep);        
        setValue('address', location.logradouro);
        setValue('complement', location.complemento);
        setValue('neighborhood', location.bairro);
        setValue('city', location.localidade);
        setValue('state', location.uf);
    }

    async function remove() {
        await removeClient(id);
    }

    useEffect(() => {
        async function init() {
            const response = await viewClient(id);
            setValue('name', response.name);
            setValue('email', response.email);
            setValue('phone', response.phone);
            setValue('address', response.endereco?.address);
            setValue('number', response.endereco?.number);
            setValue('complement', response.endereco?.complement);
            setValue('neighborhood', response.endereco?.neighborhood);
            setValue('city', response.endereco?.city);
            setValue('state', response.endereco?.state);
            setValue('postcode', response.endereco?.postcode);
        }
        init();
    }, [id, setValue]);

    return (
        <div>
            <AlertBox title="Projeto atualizado" open={alert} close={() => setAlert(false)} />
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">            
                <PrivateSidebar userType="ADMIN" />
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="flex flex-wrap items-center mb-12">
                            <div className="text-center sm:text-left">
                                <h2 className="text-3xl font-bold">
                                    Meu <span className="text-primary">Perfil</span>
                                </h2>
                            </div>
                            <div className="flex flex-row text-right ml-auto">
                                <button onClick={remove} className="flex items-center bg-red-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1">
                                    <FaRegTrashAlt className="mr-2" /> Deletar
                                </button>
                            {
                                !sending ?
                                    <button onClick={handleSubmit(onSubmit)} className="flex items-center bg-green-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1">
                                        <FaRegSave className="mr-2" /> Salvar
                                    </button> :
                                    <button className="flex items-center bg-blue-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1" disabled>
                                        <FaSpinner className="animate-spin mr-2" /> Enviando
                                    </button>
                            }
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0 break-words w-full">
                            <form>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Nome
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('name', { required: true })}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('email', { required: true })}
                                            disabled
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Telefone
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('phone')}
                                        />
                                    </div>
                                </div>
                                <h6 className="text-sm mt-3 mb-6 font-bold uppercase">
                                    Endereço
                                </h6>
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="relative w-full sm:w-1/3 mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            CEP
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('postcode', { onChange: (e) => cepFill(e.target.value) })}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercas text-xs font-bold mb-2">
                                            Logradouro
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('address')}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Número
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('number')}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Complemento
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('complement')}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Bairro
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('neighborhood')}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Cidade
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('city')}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Estado
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('state')}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}