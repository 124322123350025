import React from 'react';
import { NavLink } from "react-router-dom";

export const Menu = (props) => {
    function goRoute(ref) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className="space-y-6 block sm:inline-flex sm:p-0 sm:space-y-0 sm:space-x-8">
            <button onClick={() => goRoute(props.go.home)}      className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Início</button>
            <button onClick={() => goRoute(props.go.quemSomos)} className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Quem Somos</button>
            <button onClick={() => goRoute(props.go.bim)}       className="block font-medium uppercase opacity-75 text-white hover:opacity-100">BIM</button>
            <button onClick={() => goRoute(props.go.servicos)}  className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Serviços</button>
            <button onClick={() => goRoute(props.go.produtos)}  className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Produtos</button>
            <NavLink to="/cases" className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Portfólio</NavLink>
            <button onClick={() => goRoute(props.go.blog)}      className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Blog</button>
            <button onClick={() => goRoute(props.go.contato)}   className="block font-medium uppercase opacity-75 text-white hover:opacity-100">Contato</button>
            <NavLink to="/login" className="block font-medium uppercase opacity-75 text-primary hover:opacity-100">Login</NavLink>
        </div>
    );
}