import React, { useState, useEffect } from 'react';

import { getProjetos } from '@/lib/projetos';

import { PrivateSidebar } from '@/components/PrivateSidebar';
import { ProjCard, ProjCardLoad } from '@/components/Cards/ProjCard';

export default function ProjClientPage() {
    const [projs, setProjs] = useState([]);
    const [loading, setLoading] = useState(true);

    async function init() {
        const response = await getProjetos('client');
        setProjs(response);
        setLoading(false);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">            
                <PrivateSidebar userType="CLIENT" />
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="text-center sm:text-left mb-8">
                            <h2 className="text-3xl font-bold">
                                Todos os <span className="text-primary">Projetos</span>
                            </h2>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            {
                                !loading ? (
                                    projs.length ? 
                                        projs.map((item) => {
                                            return (
                                                <ProjCard data={item} />
                                            )
                                        }) :
                                        <span>sem resultados</span>    
                                    ) : 
                                    <>
                                        <ProjCardLoad />
                                        <ProjCardLoad />
                                        <ProjCardLoad />
                                    </>
                            }
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}