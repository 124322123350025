import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { Header } from '@/components/header';
import Footer from '@/components/Footer';

// api mockada.
import cases from '@/utils/cases';

export default function CaseSinglePage() {
    const { slug } = useParams();    
    const [data, setData] = useState(false);
     
    useEffect(() => {
        async function Init() {
            setData(cases.find(item => item.slug === slug ));
        }
        Init();
    }, [data, slug]);

    return (
        <div className="relative bg-black">
            <Header />
            <main>
                <section className="relative min-h-screen bg-black">
                    <div className="relative h-96 mb-4 mt-20 md:mb-0 w-full">
                        <div className="absolute inset-0 w-full h-full bg-gradient-to-b from-transparent to-black z-10"/>
                        <img src={data.thumbnail ?? 'nulo'} className="absolute left-0 top-0 w-full h-full z-0 object-cover" alt="" />
                        <div className="p-4 absolute bottom-0 left-0 w-full z-20">
                            <div className="max-w-screen-lg mx-auto">
                                <span className="px-4 py-1 bg-primary text-black inline-flex items-center justify-center mb-2">
                                    {data.category}
                                </span>
                                <h2 className="text-2xl font-semibold text-gray-100 leading-tight">
                                    {data.title}
                                </h2>
                                <div className="flex mt-3">
                                    <img src={data.author?.thumbnail ?? 'nulo'} className="h-10 w-10 rounded-full mr-2 object-cover" alt="" />
                                    <div>
                                        <p className="font-semibold text-gray-200 text-sm"> {data.author?.name} </p>
                                        <p className="font-semibold text-gray-400 text-xs"> {data.date} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-screen-lg mx-auto mt-12 text-gray-100 text-lg leading-relaxed">
                        <p className="pb-6" dangerouslySetInnerHTML={ {__html: data.content} }></p>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}