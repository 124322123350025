import React from 'react';
import { PrivateSidebar } from '@/components/PrivateSidebar';

export default function BlogAdmPage() {
    return (
        <div>
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">            
                <PrivateSidebar userType="ADMIN" />
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">

                </main>
            </div>
        </div>
    );
}