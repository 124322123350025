import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useUser } from '@/lib/useAuth';

// Pages
import HomePage from './pages/Home';
import EstimatePage from './pages/Estimate';
import CasesPage from './pages/Cases';
import CaseSinglePage from "./pages/Cases/Single";
import BlogPage from './pages/Blog';
import BlogSinglePage from './pages/Blog/Single';

// Areas privadas
import LoginPage from './pages/Login';
import SigninPage from './pages/Signin';

// Admin.
import DashAdmPage from './pages/AdmArea/Dash';
import BlogAdmPage from './pages/AdmArea/Blog';
import ProfileAdmPage from './pages/AdmArea/Profile';

import ProjAdmPage from './pages/AdmArea/Projetos';
import ProjSingleAdmPage from './pages/AdmArea/Projetos/Single';
import ProjNewAdmPage from './pages/AdmArea/Projetos/New';
import ProjUserAdmPage from './pages/AdmArea/Projetos/User';

import ClientAdmPage from './pages/AdmArea/Client';
import ClientNewAdmPage from './pages/AdmArea/Client/New';
import ClientSingleAdmPage from './pages/AdmArea/Client/Single';

// Cliente
import ProjClientPage from './pages/ClientArea/Projetos';
import ProfileClientPage from './pages/ClientArea/Profile';

// Funcao de rota privada.
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useUser();
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
};

const Routes = () => (
  <BrowserRouter basename="/">
    <Switch>
      <Route exact path="/login"                  component={LoginPage} />
      <Route exact path="/register"               component={SigninPage} />
      <Route exact path="/orcamento"              component={EstimatePage} />
      <Route exact path="/cases"                  component={CasesPage} />
      <Route exact path="/cases/:slug"            component={CaseSinglePage} />
      <Route exact path="/blog"                   component={BlogPage} />
      <Route exact path="/blog/:slug"             component={BlogSinglePage} />
      {/* Area do administrador */}
      <PrivateRoute exact path="/adm/dash"        component={DashAdmPage} />
      <PrivateRoute exact path="/adm/blog"        component={BlogAdmPage} />
      <PrivateRoute exact path="/adm/blog/:id"    component={BlogAdmPage} />
      <PrivateRoute exact path="/adm/proj"        component={ProjAdmPage} />
      <PrivateRoute exact path="/adm/proj/n"      component={ProjNewAdmPage} />
      <PrivateRoute exact path="/adm/proj/p/:id"  component={ProjSingleAdmPage} />
      <PrivateRoute exact path="/adm/proj/:page"  component={ProjAdmPage} />
      <PrivateRoute exact path="/adm/cli"         component={ClientAdmPage} />
      <PrivateRoute exact path="/adm/cli/n"       component={ClientNewAdmPage} />
      <PrivateRoute exact path="/adm/cli/u/:id"   component={ClientSingleAdmPage} />
      <PrivateRoute exact path="/adm/cli/p/:id"   component={ProjUserAdmPage} />
      <PrivateRoute exact path="/adm/perf"        component={ProfileAdmPage} />
      <PrivateRoute exact path="/adm/conf"        component={ClientAdmPage} />
      <Route exact path="/adm">
        <Redirect to="/adm/dash" />
      </Route>
      {/* Area do cliente */}
      <PrivateRoute exact path="/cli/proj"        component={ProjClientPage} />
      <PrivateRoute exact path="/cli/perf"        component={ProfileClientPage} />
      <PrivateRoute exact path="/cli/conf"        component={ProjClientPage} />
      <Route exact path="/cli">
        <Redirect to="/cli/dash" />
      </Route>
      <Route path="/" component={HomePage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;