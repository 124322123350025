import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { PrivateSidebar } from '@/components/PrivateSidebar';
import { AlertBox } from '@/components/Alerts';

import { FaCheck, FaSpinner } from 'react-icons/fa';
import { newClient } from '@/lib/clients';

export default function ClientNewAdmPage() {
    let goesTo = useHistory();
    const { register, handleSubmit } = useForm();

    const [alert, setAlert] = useState(false);
    const [newUserID, setNewUserID] = useState(null);
    const [sending, setSending] = useState(false);

    function endNew(id) {
        goesTo.push(`/adm/cli/u/${id}`);
    }

    const onSubmit = (formState) => {
        async function sendRequest() {
            setSending(true);
            const result = await newClient(formState);
            setSending(false);
            if (!result) return;
            setNewUserID(result?.object?.id);
            setAlert(true);
        }
        sendRequest(); 
    };

    return (
        <div>
            <AlertBox title="Cliente cadastrado" open={alert} close={() => endNew(newUserID)} />
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white text-black">            
                <PrivateSidebar userType="ADMIN"/>
                <main className="h-full ml-14 mt-14 mb-10 md:ml-64">
                    <div className="max-w-5xl mx-auto">
                        <div className="flex flex-wrap items-center mb-12">
                            <div className="text-center sm:text-left">
                                <h2 className="text-3xl font-bold">
                                    Novo <span className="text-primary">Cliente</span>
                                </h2>
                            </div>
                            <div className="flex-grow flex-1 text-right">
                            {
                                !sending ?
                                    <button onClick={handleSubmit(onSubmit)} className="flex items-center ml-auto bg-green-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1">
                                        <FaCheck className="mr-2" /> Salvar
                                    </button> :
                                    <button className="flex items-center ml-auto bg-blue-500 text-white text-xs font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1" disabled>
                                        <FaSpinner className="animate-spin mr-2" /> Enviando
                                    </button>
                            }
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0 break-words w-full">
                            <form className="w-full sm:w-96">
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Nome
                                        </label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('name', { required: true })}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            autoComplete="off"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('email', { required: true })}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Senha
                                        </label>
                                        <input
                                            type="password"
                                            autoComplete="off"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('password', { required: true })}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2">
                                            Telefone
                                        </label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="w-full px-4 py-2 mt-2 mr-4 text-base text-black transition duration-500 ease-in-out transform rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                                            {...register('phone', { required: true })}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}